<template>
  <app-tabs
    background-color="lightBg"
    :tabs="getTabsItems"
    v-model="selectedTabKeyModel"
  ></app-tabs>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      tab: null
    };
  },
  computed: {
    ...mapState('details', ['selectedTabKey']),
    ...mapGetters('details', [
      'getGallery',
      'getHotelRoomsAvailable'
    ]),
    getTabsItems() {
      return [
        { key: 'overview', label: 'Overview' },
        {
          key: 'photos',
          label: 'Photos',
          disabled: this.getGallery && this.getGallery.length === 0
        },
        {
          key: 'rooms',
          label: 'Rooms',
          disabled: !this.getHotelRoomsAvailable.length
        },
        {
          key: 'facilities',
          label: 'Facilities'
        }
        // { key: 'recommendations', label: 'Recommendations' }
      ];
    },
    selectedTabKeyModel: {
      get() {
        return this.selectedTabKey;
      },
      set(value) {
        this.setSelectedKey(value);
      }
    }
  },
  methods: {
    ...mapActions('details', {
      setSelectedKey: 'setSelectedKey'
    })
  }
};
</script>
<style lang="scss" scoped></style>
